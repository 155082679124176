import { getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseClientConfig = {
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? "",
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? "",
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? "",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? "",
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? "",
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? "",
};

// // Initialize Firebase client
const apps = getApps();
export const firebaseApp =
  apps.length > 0 ? apps[0] : initializeApp(firebaseClientConfig);
export const firebaseAuth = getAuth(firebaseApp);
