import { initApolloClient } from "@/lib/graphql/pages/initApolloClient";
import { ApolloProvider, DefaultOptions } from "@apollo/client";
import { PropsWithChildren } from "react";

export const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "cache-first",
  },
  mutate: {
    errorPolicy: "all",
  },
};

export const client = initApolloClient();

const GraphQLContextProvider = ({ children }: PropsWithChildren) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLContextProvider;
