import type { ApolloQueryResult } from "@apollo/client";
import {
  ApolloClient,
  InMemoryCache,
} from "@apollo/experimental-nextjs-app-support";
import { defaultOptions } from "../../../context/GraphQLContext";
import { link } from "./../apolloLinks";

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions,
});

export async function query<T>(
  options: Parameters<typeof apolloClient.query>[0]
): Promise<ApolloQueryResult<T>> {
  return apolloClient.query<T>(options);
}

export function getApolloClientSSR() {
  return apolloClient;
}
