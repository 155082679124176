import { Operation } from "@apollo/client";

export const publicOperationNames: Array<string> = [
  "FilterConfig",
  "ReferralClaim",
  "logIn",
  "logOut",
  "isLoggedIn",
  "getGoogleAccessToken",
  "GetReferralInfo",
  "GetShareSearchById",
];
export const internalOperationNames: Array<string> = [
  "getIsFeatureEnabled",
  "logAnonymousEventToMixpanel",
  "logAnonymousEventToCustomerIo",
  "addOrUpdateCustomerInCustomerIo",
  "isBetaWhitelisted",
];

export const isPublicOperation = (operationName?: string) => {
  return operationName && publicOperationNames.includes(operationName);
};

export const isInternalOperation = (operationName?: string) => {
  return operationName && internalOperationNames.includes(operationName);
};

const internalEndpoint =
  process.env.NEXT_PUBLIC_GRAPHQL_INTERNAL_ENDPOINT ?? "";
const publicEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_PUBLIC_ENDPOINT ?? "";
const userEndpoint = process.env.NEXT_PUBLIC_GRAPHQL_USER_ENDPOINT ?? "";

export const getCustomUri = (operation: Operation): string => {
  if (isInternalOperation(operation.operationName)) {
    return internalEndpoint;
  } else if (isPublicOperation(operation.operationName)) {
    return publicEndpoint;
  }
  return userEndpoint;
};
