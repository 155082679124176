import { PLATFORM } from "@/constants";
import { getCustomUri, isInternalOperation } from "@/utils/graphql";
import {
  ApolloLink,
  from,
  HttpLink,
  Observable,
  Operation,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";
import { SentryLink } from "apollo-link-sentry";
import packageJson from "../../../package.json";

export const internalApiKey = process.env.NEXT_PUBLIC_API_KEY ?? "";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => Sentry.captureMessage(message));
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

export const httpLink = new HttpLink({
  uri: (operation: Operation) => getCustomUri(operation),
  credentials: "include",
  fetchOptions: {
    credentials: "include",
  },
});

export const authMiddleware = setContext(async (operation, context) => {
  const xPhiaToken = isInternalOperation(operation.operationName)
    ? internalApiKey
    : "";

  return {
    ...context,
    headers: {
      ...context.headers,
      "x-phia-token": xPhiaToken,
      "x-platform": PLATFORM,
      "x-platform-version": packageJson.version,
      "x-website-vercel-env": process.env.NEXT_PUBLIC_VERCEL_ENV,
    },
  };
});

export const sentryBreadcrumbsLink = new SentryLink({
  attachBreadcrumbs: {
    includeError: true,
    includeQuery: true,
    includeVariables: true,
  },
});

// Custom Apollo Link to add Sentry tracing
export const sentryTracingLink = new ApolloLink((operation, forward) => {
  const opDefinitions = operation.query.definitions;
  // @ts-expect-error this will either be query or mutation
  const operationType = opDefinitions.find(def => def.operation).operation;

  let newSpan: Sentry.Span;
  Sentry.startSpanManual(
    {
      op: "graphql",
      name: `gql.${operation.operationName}`,
      startTime: operation.getContext().startTime,
    },
    span => {
      span.setAttribute("graphql.type", operationType);
      span.setAttribute("graphql.name", operation.operationName);
      span.setAttributes(operation.variables);
      newSpan = span;
    }
  );

  const observable = forward(operation);

  return new Observable(observer => {
    const subscription = observable.subscribe({
      next: result => {
        newSpan.setAttributes({
          // TODO: correctly grab the type here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          "graphql.response": result.data as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          "graphql.errors": result.errors as any,
        });
        observer.next(result);
      },
      error: err => {
        newSpan.setAttribute("graphql.error", err);
        newSpan.end();
        observer.error(err);
      },
      complete: () => {
        newSpan.end();
        observer.complete();
      },
    });

    return () => {
      if (!subscription.closed) {
        newSpan.setAttribute("graphql.cancelled", true);
        newSpan.end();
        subscription.unsubscribe();
      }
    };
  });
});

export const link = from([
  sentryTracingLink,
  sentryBreadcrumbsLink,
  errorLink,
  authMiddleware,
  httpLink,
]);
