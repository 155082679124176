import { sendToBackgroundViaRelay } from "@plasmohq/messaging";
import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

export type SupportedAuthProvider = "google" | "apple";

// Adds a timeout for syncing auth with extension and prevent the function from staying in pending state forever.
export async function syncAuthWithWebApp({
  timeout = 2000,
  token,
  shouldRedirect,
}: {
  timeout?: number;
  token: string | null;
  shouldRedirect: boolean;
}): Promise<void> {
  const promise = new Promise<void>((resolve, reject) => {
    sendToBackgroundViaRelay({
      name: "syncAuthWithWebApp" as never,
      body: {
        customToken: token,
        redirect: shouldRedirect ? "true" : "false",
      },
    })
      .then(resolve)
      .catch(reject);
  });

  const timeoutPromise = new Promise<void>((_, reject) => {
    setTimeout(() => {
      reject(
        new Error("Operation timed out trying to sync auth with extension")
      );
    }, timeout);
  });

  return await Promise.race([promise, timeoutPromise]);
}

export const getProvider = (providerType: SupportedAuthProvider) => {
  let provider;
  if (providerType === "google") {
    provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    provider.addScope("profile");
  } else {
    provider = new OAuthProvider("apple.com");
    provider.addScope("name");
  }
  provider.addScope("email");
  return provider;
};
