import { gql } from "@apollo/client";

export const GET_CURRENT_USER_QUERY = gql`
  query getCurrentUser {
    currentUser {
      id
      email
      firstName
      lastName
      imageUrl
      birthday
      phoneNumber
      username
      createdAt
      onboardingSource
    }
  }
`;

export const CHECK_USER_FOR_REFERRAL_CLAIM = gql`
  mutation CheckUserForReferralClaim {
    checkUserForReferralClaim {
      id
      email
      referralCodeId
      createdAt
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($userInput: UserInput!) {
    updateUser(userInput: $userInput) {
      id
      email
      firstName
      lastName
      imageUrl
      birthday
      phoneNumber
      username
      onboardingSource
    }
  }
`;

export const UPDATE_USER_ONBOARDING_MUTATION = gql`
  mutation updateUserOnboarding($userInput: UserInput!) {
    updateUser(userInput: $userInput) {
      onboardingSource
    }
  }
`;

export const IS_USERNAME_AVAILABLE_QUERY = gql`
  query IsUsernameAvailable($username: String!) {
    isUsernameAvailable(username: $username)
  }
`;
