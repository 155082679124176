import { ApolloClient, InMemoryCache } from "@apollo/client";
import { defaultOptions } from "../../../context/GraphQLContext";
import { link } from "./../apolloLinks";

export function initApolloClient() {
  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
    defaultOptions,
    ssrMode: typeof window === "undefined", // enables server-side rendering optimizations
  });
}
