"use client";

import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetGoogleAccessTokenMutation,
  GetGoogleAccessTokenMutationVariables,
  IsLoggedInQuery,
  IsLoggedInQueryVariables,
  LogInMutation,
  LogInMutationVariables,
  LogOutMutation,
  LogOutMutationVariables,
  Platform,
  User,
} from "@/__generated__/graphql";
import {
  GET_GOOGLE_ACCESS_TOKEN_MUTATION,
  IS_LOGGED_IN_QUERY,
  LOG_IN_MUTATION,
  LOG_OUT_MUTATION,
} from "@/api/auth";
import { GET_CURRENT_USER_QUERY } from "@/api/settings";
import { BETA_ACCESS_COOKIE, PLATFORM } from "@/constants";
import useAnalytics from "@/hooks/useAnalytics";
import { firebaseAuth } from "@/lib/firebase/firebase-config";
import { getApolloClientSSR } from "@/lib/graphql/app/initApolloClient";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { getProvider, SupportedAuthProvider } from "@/utils/auth";
import { useMutation, useQuery } from "@apollo/client";
import { FullStory } from "@fullstory/browser";
import { isInternalEmployee } from "@phiaco/phia-ui/dist/util";
import * as Sentry from "@sentry/nextjs";
import {
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
} from "firebase/auth";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { useRouter } from "next/navigation";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import packageJson from "../../../package.json";

export const BetaAccessError = "BetaAccessError";

type AuthContextType = {
  user: Partial<User> | undefined;
  isLoggedIn: boolean;
  isInternalUser: boolean;
  signInWithApple: (
    onboardingSource: Platform,
    onSuccess?: (isNewUser: boolean) => void
  ) => void;
  signInWithGoogleForPreviewDeployments: (
    onboardingSource: Platform,
    onSuccess?: (isNewUser: boolean) => void
  ) => void;
  signInWithGoogleAuthCode: (
    code: string,
    onboardingSource: Platform,
    onSuccess?: (isNewUser: boolean) => void
  ) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);
export interface AuthProviderProps {
  children?: React.ReactNode;
}

const client = getApolloClientSSR();

function AuthProvider({ children }: AuthProviderProps) {
  const router = useRouter();
  const deviceId = Cookies.get("mixpanel-device-id") ?? "";
  const distinctId = Cookies.get("mixpanel-distinct-id") ?? "";
  const anonymousProperties = useMemo(() => {
    return {
      distinctId,
      deviceId,
      platform: PLATFORM,
      platformVersion: packageJson.version,
    };
  }, [deviceId, distinctId]);
  const { logMixpanelEvent } = useAnalytics();
  const { data: isLoggedInData } = useQuery<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >(IS_LOGGED_IN_QUERY, { fetchPolicy: "cache-and-network" });
  const [logInMutation] = useMutation<LogInMutation, LogInMutationVariables>(
    LOG_IN_MUTATION
  );
  const [logOutMutation] = useMutation<LogOutMutation, LogOutMutationVariables>(
    LOG_OUT_MUTATION,
    {
      onError: () => {
        logMixpanelEvent({ name: MixpanelEventName.LOGOUT_ERROR });
      },
      onCompleted: () => {
        // Must clear the store on logout to avoid data leak
        client.clearStore();
        client.cache.reset();
        Cookies.remove(BETA_ACCESS_COOKIE);
        router.push(routes.HOME);
      },
      variables: anonymousProperties,
    }
  );
  const [getGoogleAccessTokenMutation] = useMutation<
    GetGoogleAccessTokenMutation,
    GetGoogleAccessTokenMutationVariables
  >(GET_GOOGLE_ACCESS_TOKEN_MUTATION);
  const { data: userData } = useQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >(GET_CURRENT_USER_QUERY, {
    skip: !isLoggedInData?.isLoggedIn,
    fetchPolicy: "network-only",
  });

  const isInternalUser = useMemo(
    () =>
      userData?.currentUser
        ? isInternalEmployee(userData.currentUser.email)
        : false,
    [userData?.currentUser]
  );
  const { setMixpanelUserProperties, setCustomerIoUserProperties } =
    useAnalytics();

  useEffect(() => {
    if (userData?.currentUser) {
      mixpanel.identify(userData?.currentUser.id);
      setMixpanelUserProperties();
      setCustomerIoUserProperties();
    }
    Sentry.setUser({ email: userData?.currentUser?.email });
    Sentry.setContext("Source", {
      source: "marketplace-web",
      version: packageJson.version,
    });
    // Initialize FullStory for session replays - exclude internal employees
    if (
      process.env.NEXT_PUBLIC_VERCEL_ENV === "production" &&
      userData?.currentUser?.id
    ) {
      FullStory("setIdentity", {
        properties: {
          id: userData?.currentUser?.id,
        },
      });
    }
  }, [
    userData?.currentUser,
    isInternalUser,
    setMixpanelUserProperties,
    setCustomerIoUserProperties,
  ]);

  const logout = useCallback(() => {
    logOutMutation();
  }, [logOutMutation]);

  const signInWithApple = useCallback(
    async (
      onboardingSource: Platform,
      onSuccess?: (isNewUser: boolean) => void
    ) => {
      const providerName: SupportedAuthProvider = "apple";
      const provider = getProvider(providerName);
      const result = await signInWithPopup(firebaseAuth, provider);
      const token = await result.user.getIdToken();

      await logInMutation({
        variables: {
          idToken: token,
          onboardingSource,
          ...anonymousProperties,
        },
        refetchQueries: [
          { query: IS_LOGGED_IN_QUERY },
          { query: GET_CURRENT_USER_QUERY },
        ],
        onError: () => {
          const properties = {
            onboarding_source: onboardingSource,
            provider: providerName,
            email: result?.user?.email,
          };
          logMixpanelEvent({ name: MixpanelEventName.LOGIN_ERROR, properties });
          throw new Error(BetaAccessError);
        },
        onCompleted: data => {
          const isNewUser = !data.logIn.onboardedAt;
          logMixpanelEvent({
            name: MixpanelEventName.LOGIN_SUCCESS,
            properties: { is_new: isNewUser, provider: providerName },
          });
          onSuccess?.(isNewUser);
        },
      });
    },
    [logInMutation, anonymousProperties, logMixpanelEvent]
  );

  // We must use this sign in approach to ensure users can successfully login
  // via Google SSO on any browser. The `signInWithPopup` approach does not
  // work on Safari.
  const signInWithGoogleAuthCode = useCallback(
    async (
      code: string,
      onboardingSource: Platform,
      onSuccess?: (isNewUser: boolean) => void
    ) => {
      const providerName: SupportedAuthProvider = "google";
      const accessTokenData = await getGoogleAccessTokenMutation({
        variables: { authCode: code },
      });
      const accessToken = accessTokenData?.data?.getGoogleAccessToken.id_token;
      if (!accessToken) {
        throw new Error("Unable to fetch access token from Google");
      }
      const googleCredential = GoogleAuthProvider.credential(accessToken);
      const userCredential = await signInWithCredential(
        firebaseAuth,
        googleCredential
      );
      const userToken = await userCredential.user.getIdToken(true);
      await logInMutation({
        variables: {
          idToken: userToken,
          onboardingSource,
          ...anonymousProperties,
        },
        refetchQueries: [
          { query: IS_LOGGED_IN_QUERY },
          { query: GET_CURRENT_USER_QUERY },
        ],
        onError: () => {
          const properties = {
            onboarding_source: onboardingSource,
            provider: providerName,
            email: userCredential?.user?.email,
          };
          logMixpanelEvent({ name: MixpanelEventName.LOGIN_ERROR, properties });
          throw new Error(BetaAccessError);
        },
        onCompleted: async data => {
          const isNewUser = !data.logIn.onboardedAt;
          logMixpanelEvent({
            name: MixpanelEventName.LOGIN_SUCCESS,
            properties: { is_new: isNewUser, provider: providerName },
          });
          // setting beta access cookie as ext onboarding isnt blocked by beta access page
          const userEmail = data.logIn.email;
          // Set cookie so it doesn't expire for a year
          Cookies.set(BETA_ACCESS_COOKIE, userEmail, { expires: 365 });
          await onSuccess?.(isNewUser);
        },
      });
    },
    [
      logInMutation,
      getGoogleAccessTokenMutation,
      logMixpanelEvent,
      anonymousProperties,
    ]
  );

  // We only use this function when logging in for preview deployments
  const signInWithGoogleForPreviewDeployments = useCallback(
    async (
      onboardingSource: Platform,
      onSuccess?: (isNewUser: boolean) => void
    ) => {
      const providerName: SupportedAuthProvider = "google";
      const provider = getProvider(providerName);
      const result = await signInWithPopup(firebaseAuth, provider);
      const idToken = await result.user.getIdToken();

      await logInMutation({
        variables: {
          idToken: idToken,
          onboardingSource,
          ...anonymousProperties,
        },
        refetchQueries: [
          { query: IS_LOGGED_IN_QUERY },
          { query: GET_CURRENT_USER_QUERY },
        ],
        onError: () => {
          throw new Error(BetaAccessError);
        },
        onCompleted: data => {
          const isNewUser = !data.logIn.onboardedAt;
          onSuccess?.(isNewUser);
        },
      });
    },
    [logInMutation, anonymousProperties]
  );

  const value = {
    user: userData?.currentUser,
    isInternalUser,
    isLoggedIn: isLoggedInData?.isLoggedIn ?? true,
    logout,
    signInWithApple,
    signInWithGoogleAuthCode,
    signInWithGoogleForPreviewDeployments,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// useAuthContext is a custom hook that simplifies consuming the AuthContext
export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(
      "useAuthContext must be used within an AuthContextProvider"
    );
  }

  return context;
};

export default AuthProvider;
